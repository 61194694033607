import * as React from "react";
import * as ReactDOM from "react-dom";
import Search from "./components/Search";

var root = document.getElementById("content");

ReactDOM.render(
  <React.StrictMode>
    <Search />
  </React.StrictMode>,
  root
);
