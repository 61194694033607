import { useState } from "react";

const Search = () => {
  const [municipalityName, setMunicipalityName] = useState("")
  const [res, setRes] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
      e.preventDefault();
      if (municipalityName) {
          setLoading(true);
          // GET request using fetch with set headers
          const headers = { "Content-Type": "application/json" };
          fetch(
              `/nb-no/FindMyGraveAuthority?municipalityName=${municipalityName}`,
              { headers }
          )
          .then((response) => response.json())
          .then((data) => {
              setLoading(false);
              setRes(data);
          });
      }
  };

  return (
    <div className="padding--top-btm--24">
      <form
        autoComplete="off"
        className="form"
        onSubmit={(e) => handleSubmit(e)}
       >
        <label className="form__streetName">
            <span className="form__label">Kommunenavn</span>
            <input
                className="form__input"
                required
                placeholder="Kommune"
                autoComplete="off"
                data-lpignore="true"
                type="text"
                value={municipalityName}
                onChange={(e) => setMunicipalityName(e.target.value)}
            />
        </label>
        <div className="form__submit">
          <button className="button--green" type="submit">
            Søk
          </button>
        </div>
      </form>
      <div className="grid">
        {loading && (
          <div class="graveAuthority-message">
            <span>Laster..</span>
          </div>
        )}
      </div>

      {res.success &&
        !loading &&
        (res?.result?.length > 0 ? (
          <ul className="graveAuthority-ul grid">
            {res.result?.map((el) => {
              return (
                <li className="graveAuthority-ul__li" key={el.name}>
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href={`${el.url}`}
                  >
                    <div className="graveAuthority-ul__li__content">
                      {" "}
                      <span className="graveAuthority-ul__li__content__title">
                        {el.name}
                      </span>
                      <div aria-hidden="true" className="graveAuthority-ul__li__content__select-wrapper">
                        <span className="graveAuthority-ul__li__content__select-wrapper__title">
                          Velg
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#333333"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z" />
                        </svg>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="grid">
            <div class="graveAuthority-message">
              <span>Ingen treff. Det er mulig denne kommunen som du søker etter ikke er med på gravplass.no, men at de har sin egen infoside.</span>
            </div>
          </div>
        ))}
      <div className="grid">
        {!res?.success && res?.errorMessage && !loading && (
          <div class="graveAuthority-message">
            <span>Ingen treff. Det er mulig denne kommunen som du søker etter ikke er med på gravplass.no, men at de har sin egen infoside.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;